<script setup>
import { ref } from 'vue'
import Spinner from '../../assets/icons/spinner.svg'

const props = defineProps({
  share: { type: Function, required: true },
})

const loading = ref(false)

const shareWithState = async () => {
  loading.value = true
  await props.share()
  loading.value = false
}
</script>

<template>
  <button class="c-button" :disabled="loading" @click="shareWithState">
    <Spinner v-if="loading" class="spinner" />
    <slot v-else />
  </button>
</template>

<style lang="scss" scoped>
.spinner {
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
