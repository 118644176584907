<template>
  <div class="c-day-picker">
    <Day v-for="day in days" :key="day" :day="day" @select="onDayClick" />
  </div>
</template>

<script setup>
import { START_DATE } from '../../constants'
import Day from './Day.vue'

const days = Array.from({ length: 5 }, (_, i) => {
  const date = new Date(START_DATE)
  date.setDate(START_DATE.getDate() + i)
  return date
})

const emit = defineEmits(['select'])
const onDayClick = (day) => {
  emit('select', day)
}
</script>

<style scoped lang="scss">
.c-day-picker {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 40px;
  gap: 1em;
}
</style>
