<template>
  <div v-auto-animate class="c-page">
    <template v-if="currentPage === 'authenticate'">
      <Login @changePage="changePage" />
    </template>
    <template v-else-if="currentPage === 'decision'">
      <Decision @changePage="changePage" />
    </template>
    <template v-else-if="currentPage === 'datePicker'">
      <DatePicker @changePage="changePage" />
    </template>
    <template v-else-if="currentPage === 'form'">
      <QuestionForm @changePage="changePage" />
    </template>
    <template v-else-if="currentPage === 'subscribed'">
      <Subscribed @changePage="changePage" />
    </template>
  </div>
</template>

<script setup>
import { useFormStore } from '../../stores/form'
import Subscribed from './Subscribed.vue'
import QuestionForm from '../questionForm/QuestionForm.vue'
import DatePicker from '../datePicker/DatePicker.vue'
import Decision from './Decision.vue'
import Login from './Login.vue'
import { dataLayer, authentication } from '@dpgradio/creative'
import { ref } from 'vue'
import { storeToRefs } from 'pinia'

const formStore = useFormStore()
const { subscribed } = storeToRefs(formStore)
const authenticated = ref(authentication.radioToken)
const currentPage = ref(authenticated.value ? 'decision' : 'authenticate')

if (subscribed.value) {
  currentPage.value = 'subscribed'
}

const changePage = async (page) => {
  if (page === 'decision') {
    currentPage.value = 'decision'
    formStore.setSelectedDate(null)
  } else if (page === 'datePicker') {
    currentPage.value = 'datePicker'
    formStore.setSelectedDate(null)
  } else if (page === 'form') {
    currentPage.value = 'form'
  } else if (page === 'submit') {
    await formStore.signUp()
    currentPage.value = 'subscribed'
  }

  dataLayer.pushEvent('virtual_page_view', { page: page })
}
</script>

<style scoped lang="scss">
.c-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.o-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
