<template>
  <div v-auto-animate class="date-picker">
    <h1>WANNEER ZAL DOMIEN GEPAKT WORDEN?</h1>
    <template v-if="!day">
      <DayPicker @select="setDay" />
    </template>
    <template v-else>
      <span class="date">
        <h2>
          {{ day.toLocaleDateString('NL-nl', { weekday: 'long' }) }}
          <span>{{ day.getDate() }}</span>
          mei 2024 om:
        </h2>
      </span>
      <TimePicker :date="day" @update="setTime" @reset="resetTime" />
    </template>
    <button v-if="day" class="c-button" :disabled="error || !time" @click="onQuestionSubmit">Verder</button>
    <button class="c-button c-button--secondary" @click="back()">Terug</button>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import DayPicker from './DayPicker.vue'
import TimePicker from './TimePicker.vue'
import { useFormStore } from '../../stores/form'
const emit = defineEmits(['save'])

const day = ref(null)
const time = ref(null)

const setDay = (value) => {
  day.value = value
}

const back = () => {
  useFormStore().setSelectedDate(null)
  emit('changePage', 'decision')
}

const setTime = ({ hours, minutes, seconds }) => {
  time.value = { hours, minutes, seconds }
  day.value.setHours(hours)
  day.value.setMinutes(minutes)
  day.value.setSeconds(seconds)
}

const resetTime = () => {
  time.value = null
}

const onQuestionSubmit = async () => {
  useFormStore().setSelectedDate(day.value)
  emit('changePage', 'form')
}
</script>

<style lang="scss" scoped>
h2 {
  margin: 10px 0 0;
}

h1 {
  margin: 0 0 10px;
}

.back {
  margin: 20px 0 30px;
  color: rgba(var(--q-grey), 0.5);
}

.date-picker {
  max-width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .c-input-group {
    width: 100% !important;
  }
}
</style>
