<template>
  <div id="signup-client" class="o-signup-client">
    <div class="o-content">
      <div class="o-header">
        <img alt="header-image" src="../assets/img/logo.png" class="c-header-image" />
      </div>
      <div class="o-body">
        <div class="o-body__content">
          <Running v-if="isRunning" />
          <h1 v-else-if="isRunning === false">De actie is jammer genoeg afgelopen.</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { dataLayer, api, socket } from '@dpgradio/creative'
import { onMounted, ref } from 'vue'
import Running from '../components/steps/Running.vue'

const STOPWATCH_ID = 1
const isRunning = ref(null)
const stopwatch = ref(null)

onMounted(async () => {
  await fetchStopwatch()
  if (!stopwatch.value.endTime) {
    listenToSockets()
    isRunning.value = true
  }
})

const fetchStopwatch = async () => {
  stopwatch.value = (await api.request().get(`/stopwatches/${STOPWATCH_ID}.json`)).stopwatch
}

const listenToSockets = () => {
  const stationSocket = socket.connect('qmusic_nl')

  stationSocket.subscribe({ stopwatch: STOPWATCH_ID }).on('stop', () => {
    isRunning.value = false
  })
}

dataLayer.pushEvent('actie_meta', {
  name: 'Wanted',
  type: 'form',
  station: 'qmusic_nl',
})
</script>
