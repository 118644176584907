<template>
  <div class="time_input">
    <label for="hours">
      <select id="hours" placeholder="09" :value="hours" @change="setHours">
        <option v-for="i in hourOptions" :key="i" :value="i">{{ i.toString().padStart(2, '0') }}</option>
      </select>
      <span class="label">UUR</span>
    </label>
    <span class="divider">:</span>
    <label for="minutes">
      <select id="minutes" :value="minutes" @change="setMinutes">
        <option v-for="i in minuteOptions" :key="i" :value="i">{{ i.toString().padStart(2, '0') }}</option>
      </select>
      <span class="label">MINUTEN</span>
    </label>
    <span class="divider">:</span>
    <label for="seconds">
      <select id="seconds" :value="seconds" @change="setSeconds">
        <option v-for="i in 60" :key="i" :value="i - 1">{{ (i - 1).toString().padStart(2, '0') }}</option>
      </select>
      <span class="label">SECONDEN</span>
    </label>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { END_DATE } from '../../constants'

const props = defineProps({
  date: {
    type: Date,
    required: true,
  },
})
const emits = defineEmits(['update'])

const hours = ref(null)
const minutes = ref(null)
const seconds = ref(null)
const MINUTES_SAFE_ZONE = 30

const emitTime = () => {
  if (!hours.value || !minutes.value || !seconds.value) {
    emits('reset', null)
  } else {
    emits('update', {
      hours: hours.value,
      minutes: minutes.value,
      seconds: seconds.value,
    })
  }
}

const hourOptions = computed(() => {
  const now = new Date()
  const isToday = new Date(props.date).getDate() === now.getDate()
  const isEndDate = new Date(props.date).getDate() === END_DATE.getDate()
  let MIN_HOUR = 8
  if (isToday) {
    MIN_HOUR = now.getHours()
    if (now.getMinutes() > MINUTES_SAFE_ZONE) {
      MIN_HOUR += 1
    }
  }
  const MAX_HOUR = isEndDate ? 11 : 18
  return Array.from({ length: MAX_HOUR - MIN_HOUR + 1 }, (_, i) => MIN_HOUR + i)
})

const minuteOptions = computed(() => {
  const now = new Date()
  let MIN_MINUTE = 0
  const isToday = new Date(props.date).getDate() === now.getDate()
  if (!isToday) return Array.from({ length: 60 }, (_, i) => i)
  if (hours.value == now.getHours()) {
    MIN_MINUTE = Math.max(now.getMinutes() + MINUTES_SAFE_ZONE, MINUTES_SAFE_ZONE)
  } else if (hours.value == now.getHours() + 1 && now.getMinutes() > MINUTES_SAFE_ZONE) {
    MIN_MINUTE = MINUTES_SAFE_ZONE - (60 - now.getMinutes())
  }
  return Array.from({ length: 60 - MIN_MINUTE }, (_, i) => MIN_MINUTE + i)
})

const setHours = (e) => {
  hours.value = e.target.value
  emitTime()
}

const setMinutes = (e) => {
  minutes.value = e.target.value
  emitTime()
}

const setSeconds = (e) => {
  seconds.value = e.target.value
  emitTime()
}
</script>

<style lang="scss" scoped>
.time_input {
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-size: 2rem;

  select {
    width: 80px;
    height: 60px;
    border: none;
    box-sizing: border-box;
    font-family: var(--font-title);
    text-transform: uppercase;
    letter-spacing: var(--letter-spacing-title);
    font-weight: 600;
    text-align: center;
    color: rgb(var(--q-grey));
    border-radius: 8px;
    font-size: 2.2rem;
    padding: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-align: center;
    text-align-last: center;

    option {
      font-size: 1rem;
    }

    &::-ms-expand {
      display: none;
    }

    &:focus {
      outline: 3px solid var(--active);
    }

    &::placeholder {
      color: rgba(var(--q-grey));
      opacity: 0.4;
    }
  }

  label {
    color: rgb(var(--q-red));
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }

  .label {
    font-size: 16px;
    width: 100%;
    line-height: 16px;
    margin-top: 5px;
    text-align: center;
    color: white;
  }
}

.error {
  color: white;
  font-size: 1rem;
}

.divider {
  color: white;
  font-size: 2rem;
  margin-bottom: 20px;
}
</style>
