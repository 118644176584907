import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { configuration, setupAirbrake, dataLayer, privacy, authentication } from '@dpgradio/creative'
import { Notifier as AirbrakeNotifier } from '@airbrake/browser'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'

import './assets/css/index.scss'

// ROUTES
import App from './App.vue'
import { AIRBRAKE_PROJECT_ID, AIRBRAKE_PROJECT_KEY } from './constants'

window.createVueApp = async () => {
  await configuration.retrieveConfigForStation('qmusic_nl')

  authentication.initialize()

  const pinia = createPinia()
  const app = createApp(App)

  if (import.meta.env.PROD) {
    await setupAirbrake(
      AirbrakeNotifier,
      {
        projectId: AIRBRAKE_PROJECT_ID,
        projectKey: AIRBRAKE_PROJECT_KEY,
        version: import.meta.env.VITE_COMMIT_HASH,
      },
      app
    )
  }

  app.use(pinia)
  app.use(autoAnimatePlugin)
  app.mount('#app')

  privacy.initialize()
  dataLayer.initialize()
  dataLayer.pushVirtualPageView()
}

window.createVueApp()
