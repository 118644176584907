<script setup>
import { Shareable, ImageGeneratorProperties } from '@dpgradio/creative/share'
import { config } from '@dpgradio/creative'
import ShareButton from './ShareButton.vue'
import FacebookIcon from '../../assets/icons/facebook.svg'
import InstagramIcon from '../../assets/icons/instagram.svg'
import WhatsAppIcon from '../../assets/icons/whatsapp.svg'

const shareable = new Shareable()
  .withTitle('Dit is mijn voorspelling voor Wanted Domien.')
  .withDescription('Geef ook die van jou door en maak kans op €10.000,-.')
  .withMessageText(
    `Dit is mijn voorspelling voor Wanted Domien. Geef ook die van jou door en maak kans op €10.000,-. ${window.location.href}`
  )
  .redirectTo(window.location.href)
  .fromDomain(config('domain'))

const createImageProperties = (width, height, platform) =>
  new ImageGeneratorProperties(`https://add-form.wanted-form.pages.dev/share?platform=${platform}`)
    .withDimensions(width, height)
    .withPayload({
      guess: localStorage.getItem('Wanted'),
    })

const shareOnFacebook = async () => {
  const image = createImageProperties(1200, 630, 'facebook')

  ;(await shareable.generateUsingImage(image)).openFacebookUrl()
}

const shareOnInstagram = async () => {
  const image = createImageProperties(1080, 1920, 'instagram')

  ;(await shareable.generateUsingImage(image)).openInstagramUrl()
}

const shareOnWhatsApp = async () => {
  const image = createImageProperties(1200, 630, 'facebook')

  ;(await shareable.generateUsingImage(image)).openWhatsappUrl()
}
</script>

<template>
  <div>
    <h2>DEEL JOUW VOORSPELLING</h2>
    <div class="button-group">
      <ShareButton :share="shareOnFacebook">
        <FacebookIcon class="icon" />
        Facebook
      </ShareButton>
      <ShareButton :share="shareOnInstagram">
        <InstagramIcon class="icon" />
        Instagram
      </ShareButton>
      <ShareButton :share="shareOnWhatsApp">
        <WhatsAppIcon class="icon" />
        WhatsApp
      </ShareButton>
    </div>
  </div>
</template>

<style scoped lang="scss">
.icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;

  .c-button {
    max-width: 150px;
  }
}
</style>
