<template>
  <h1>WORDT DOMIEN BINNEN 100 UUR GEPAKT DOOR BRAM?</h1>
  <div class="c-decision">
    <button class="c-button" @click="onDecision(true)">JA</button>
    <button class="c-button" @click="onDecision(false)">NEE</button>
  </div>
</template>

<script setup>
import { useFormStore } from '../../stores/form'
const emit = defineEmits(['changePage'])

const onDecision = async (descision) => {
  if (descision) {
    emit('changePage', 'datePicker')
  } else {
    useFormStore().setSelectedDate(null)
    emit('changePage', 'form')
  }
}
</script>

<style lang="scss" scoped>
.c-decision {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-wrap: balance;
  gap: 1em;
  width: 100%;
  max-width: 90vw;

  .c-button {
    max-width: 100px;
  }
}
</style>
