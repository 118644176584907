import { api, authentication, dataLayer } from '@dpgradio/creative'
import { defineStore } from 'pinia'
import { FORM_ID } from '../constants'
import { intervalToDuration } from 'date-fns'
import { START_DATE, QUESTION_DATE_ID, QUESTION_DURATION_STRING_ID } from '../constants'

const pad = (n) => (n < 10 ? `0${n}` : n)

export const useFormStore = defineStore('form', {
  state: () => ({
    subscribed: localStorage.getItem('Wanted') ?? false,
    selectedDate: null,
    answers: {},
    isSigningUp: false,
  }),
  getters: {
    durationString() {
      if (!this.selectedDate) return '100:00:00'

      const duration = intervalToDuration({
        start: START_DATE,
        end: this.selectedDate,
      })
      const hours = (duration.days || 0) * 24 + (duration.hours || 0)
      return `${hours} uur, ${duration.minutes || 0} minuten en ${duration.seconds || 0} seconden`
    },
    duration() {
      if (!this.selectedDate) return 'niet gepakt'

      const interval = intervalToDuration({ start: START_DATE, end: this.selectedDate })
      const hours = (interval.days || 0) * 24 + (interval.hours || 0)
      return `${pad(hours)}:${pad(interval.minutes)}:${pad(interval.seconds)}`
    },
  },
  actions: {
    setSelectedDate(date) {
      this.selectedDate = date
      this.answers = {
        ...this.answers,
        [QUESTION_DATE_ID]: date,
        [QUESTION_DURATION_STRING_ID]: this.durationString,
      }
    },
    setAnswers(answers) {
      this.answers = {
        ...this.answers,
        ...answers,
      }
    },
    async signUp() {
      if (this.isSigningUp) {
        return
      }

      this.isSigningUp = true
      const answerAttributes = Object.assign(
        {},
        Object.entries(this.answers)
          .filter(([, answer]) => {
            return answer
          })
          .map(([id, answer]) => ({
            webform_question_id: id,
            content: answer,
          }))
      )

      try {
        await api.request().post(`https://qmusic.nl/webform/forms/${FORM_ID}/submissions.js`, {
          webform_submission: {
            answers_attributes: answerAttributes,
          },
          token: authentication.radioToken,
        })

        dataLayer.pushEvent('submit_success', {
          type: 'form',
          form_id: FORM_ID,
        })
        localStorage.setItem('Wanted', this.duration)
        this.subscribed = true
      } catch (e) {
        dataLayer.pushEvent('submit_failure', {
          type: 'form',
          form_id: FORM_ID,
          error: e,
        })
        throw e
      } finally {
        this.isSigningUp = false
      }
    },
  },
})
