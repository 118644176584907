<template>
  <div class="c-input-group">
    <p class="c-input-label">
      <strong>{{ question.text }}</strong>
    </p>
    <div v-if="isCheckbox" class="checkbox-group">
      <input id="checkbox" value="false" class="styled-checkbox" type="checkbox" @input="onInput" />
      <label for="checkbox"> {{ question.checkboxText }} </label>
    </div>
    <textarea v-else-if="isTextArea" :value="defaultValue" rows="4" @input="onInput" />
    <input v-else :type="question.type" :value="defaultValue" @input="onInput" />
  </div>
</template>

<script>
export default {
  name: 'Question',
  props: ['question', 'radioButtonColor', 'defaultValue', 'type'],
  data() {
    return {
      answers: {},
    }
  },
  computed: {
    isCheckbox() {
      return this.question.type === 'checkbox'
    },
    isTextArea() {
      return this.question.type === 'textarea'
    },
  },
  methods: {
    onInput(value) {
      this.$emit('input', value)
    },
  },
}
</script>
