import { api } from '@dpgradio/creative'
import { defineStore } from 'pinia'

export const useProfileStore = defineStore('profile', {
  state: () => ({
    profile: {},
  }),
  actions: {
    async fetchProfile() {
      this.profile = await api.members.me()
    },
  },
})
