<template>
  <time class="c-day-picker-day" :class="{ disabled: isDisabled }" @click="onDayClick(day)">
    <em>mei</em>
    <strong>{{ day.toLocaleDateString('NL-nl', { weekday: 'long' }) }}</strong>
    <span>{{ day.getDate() }}</span>
  </time>
</template>

<script setup>
import { computed, defineProps } from 'vue'

const props = defineProps({
  day: { type: Date, required: true },
})
const emit = defineEmits(['select'])

const isDisabled = computed(() => {
  return new Date(props.day).setUTCHours(23, 59, 59, 999) < new Date()
})

const onDayClick = () => {
  if (isDisabled.value) return
  emit('select', props.day)
}
</script>

<style scoped lang="scss">
.c-day-picker-day {
  font-size: 1rem;
  display: block;
  position: relative;
  width: 6em;
  height: 6em;
  background-color: #fff;
  border-radius: 0.5em;
  border: 2px solid rgb(var(--q-red));
  overflow: hidden;
  cursor: pointer;

  &:hover {
    border: 2px solid #fff;
  }

  * {
    display: block;
    width: 100%;
    font-size: 1rem;
    font-weight: bold;
    font-style: normal;
    text-align: center;
  }

  strong {
    position: absolute;
    top: 0;
    padding: 0.4em 0;
    color: #fff;
    background-color: rgb(var(--q-red));
    border-bottom: 1px dashed rgb(var(--q-red));
    box-shadow: 0 2px 0 rgb(var(--q-red));
  }

  em {
    position: absolute;
    bottom: 0.3em;
    color: #aaa;
  }

  span {
    font-size: 2.3em;
    letter-spacing: -0.05em;
    padding-top: 0.9em;
    color: rgb(var(--q-grey));
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
