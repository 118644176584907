<template>
  <h1>Log in om mee te doen</h1>
  <button class="c-button" @click="logIn">Log in</button>
</template>

<script setup>
import { authentication } from '@dpgradio/creative'

const emit = defineEmits(['changePage'])

const logIn = async () => {
  await authentication.require()
  emit('changePage', 'decision')
}
</script>
