<template>
  <div class="question-form">
    <h1>VERSTUUR JOUW VOORSPELLING</h1>
    <span v-if="selectedDate" class="time">
      Je voorspelt dat Domien gepakt gaat worden op
      <strong>
        {{ selectedDate.toLocaleDateString('NL-nl', { weekday: 'long' }) }} om
        {{ selectedDate.toLocaleTimeString() }} </strong
      >. Je denkt dus dat hij <strong>{{ durationString }}</strong> uit handen van Bram kan blijven.
    </span>
    <span v-else class="time">
      Je denkt dat Domien <strong>niet gepakt</strong> gaat worden door Bram binnen 100 uur.
    </span>

    <Question
      v-for="question in questions"
      :key="question.id"
      :question="question"
      :defaultValue="question.defaultValue"
      @change="(event) => updateAnswer(question, event.target.value)"
    />

    <span v-if="errors.length">
      <p class="c-error">Gelieve alle velden in te vullen</p>
    </span>
    <button class="c-button" @click="onSave">stuur voorspelling in</button>
    <button class="c-button c-button--secondary" @click="goBack">Terug</button>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { removePhoneNumberCountryPrefix } from '@dpgradio/creative'
import { storeToRefs } from 'pinia'
import { useProfileStore } from '../../stores/profile'
import { useFormStore } from '../../stores/form'
import Question from './Question.vue'

const emit = defineEmits(['changePage'])
const store = useProfileStore()
const formStore = useFormStore()

const answers = ref({})
const errors = ref([])

const { profile } = storeToRefs(store)
const { durationString, selectedDate } = storeToRefs(formStore)

const questions = computed(() => [
  {
    text: 'Voornaam',
    type: 'text',
    required: true,
    defaultValue: profile.value.first_name,
    id: 7003,
  },
  {
    text: 'Achternaam',
    type: 'text',
    required: true,
    defaultValue: profile.value.last_name,
    id: 7005,
  },
  {
    text: 'E-mail',
    type: 'email',
    required: true,
    defaultValue: profile.value.email,
    id: 7007,
  },
  {
    text: 'Telefoonnummer',
    type: 'tel',
    required: true,
    defaultValue: removePhoneNumberCountryPrefix(profile.value.mobile),
    id: 7009,
  },
  {
    text: 'Geboortedatum',
    type: 'date',
    required: true,
    defaultValue: profile.value.birthday,
    id: 7011,
  },
  {
    checkboxText:
      'Ja, ik blijf graag verder via e-mail op de hoogte van alle toekomstige acties en evenementen van Qmusic.',
    text: 'Meer Qmusic?',
    type: 'checkbox',
    id: 7017,
  },
])

onMounted(async () => {
  await store.fetchProfile()
  answers.value = questions.value.reduce((agg, q) => {
    agg[q.id] = q.defaultValue
    return agg
  }, {})
})

const updateAnswer = (question, answer) => {
  if (question.type === 'checkbox') {
    answers.value[question.id] = answer ? question.checkboxText : ''
  } else {
    answers.value[question.id] = answer
  }
}

const onSave = () => {
  errors.value = []
  questions.value.forEach((question) => {
    if (question.required && !answers.value[question.id]) {
      errors.value.push(question.id)
    }
  })

  if (errors.value.length === 0) {
    formStore.setAnswers(answers.value)
    emit('changePage', 'submit')
  }
}

const goBack = () => {
  emit('changePage', 'decision')
}
</script>

<style scoped>
.c-button {
  max-width: 80%;
}

.time {
  font-size: 1.2rem;
  color: white;
  text-wrap: balance;
}

.question-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
